import { AlertService } from './../services/alert.service';
import { Router } from '@angular/router';
import { StorageService } from './../services/storage.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { HomeService } from '../services/home.service';


const JWT_INVALID_TOKEN = 'jwt_auth_invalid_token';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private isRefreshing = false;
    constructor(private authenticationService: AuthenticationService,
        private storageService: StorageService,
        private router: Router,
        private alertService: AlertService,
        private homeService: HomeService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            const realError = err.error;
            if (err.status === 403 && err.error.code === JWT_INVALID_TOKEN) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
            } else if (err.status === 500 || err.error.status === 500) {
                if (err.error.message === "Session logged out. Please login again!" || err.message === "Session logged out. Please login again!") {
                    this.storageService.clear().then(data=>{
                        this.storageService.clear().then(data=>{
                            this.router.navigateByUrl("/public/login");
                        });
                      });
                        // this.handle401Error(request, next);                                       
                } else {
                    this.alertService.presentAlert('Error!', '', err.error ? err.error.message != null ? err.error.message : 'Oops! Something went wrong' : err.message != null ? err.message : 'Oops! Something went wrong', 'error');
                }
            } else if (err.status === 404 && err.statusText == "Not Found") {
                
            // }  else if (err.status === 401) {
                // this.homeService.refreshToken(this.storageService.get('refreshToken')).subscribe(
                //   data=> {
                //     this.storageService.set('token', data.body.accessToken);
                //   //  console.log(data);
                //   }
                // )

                // attempting to refresh our token
             } else if (err.error.status === 400) {
                if(err?.error?.body?.error_description == "Authorization code doesn't exist or is invalid for the client"){

                }else if(err && err.error && err.error.message === "User is already logged in on another device"){
                    
                } else if(err && err.error && err.error.message === "No ABHA user registered with this Aadhaar number. Please use create Abha to generate Abha Number."){

                }else if(err && err.error && err.error.message === "User not found."){

                }
                else if(err && err.error && err.error.message === "Invalid LoginId"){
                    this.alertService.presentAlert('Error!', '','Aadhaar Number is not valid', 'error');
                }
                else if(err && err.error && err.error.message === "OTP validation failed"){
                    this.alertService.presentAlert('Error!', '','Invalid Aadhaar OTP', 'error');
                }else if(err && err.error && err.error.message === " OTP validation failed"){
                    this.alertService.presentAlert('Error!', '','Invalid Aadhaar OTP', 'error');
                }
                // Aadhaar Number is not valid
                else{
                    this.alertService.presentAlert('Error!', '', err.error ? err.error.error != null ? err.error.error : err.error.message != null ? err.error.message : 'Oops! Something went wrong' : err.message != null ? err.message : 'Oops! Something went wrong', 'error');
                }

             }
             else {
               // console.log(err.error);
                //console.log(err.error.message);
                this.alertService.presentAlert('Error!', '', err.error ? err.error.error != null ? err.error.error : err.error.message != null ? err.error.message : 'Oops! Something went wrong' : err.message != null ? err.message : 'Oops! Something went wrong', 'error');
            }

            // const error = err.error.message || err.error.detail || err.statusText;
            return throwError(realError);
        }));
    }
    private addToken(request: HttpRequest<any>, token: any) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }
    async refreshToken(request, next){
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            let refreshToken = await this.storageService.get('refreshToken');
            this.homeService.refreshToken(refreshToken).subscribe(
                (data:any)=> {
                this.storageService.set('token', data.body.accessToken);
                this.isRefreshing = false;
                this.refreshTokenSubject.next(data.body.accessToken);
                return next.handle(this.addToken(request, data.body.accessToken));
            })
        }else{
            let token = await this.storageService.get('token');
            return next.handle(this.addToken(request, token));
            // return this.refreshTokenSubject.pipe(
            //     filter(token => token != null),
            //     take(1),
            //     switchMap(jwt => {
            //         return next.handle(this.addToken(request, jwt));
            //     }));
        }                
    }

    private async handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            let refreshToken = await this.storageService.get('refreshToken');
            console.log(refreshToken);
            return this.homeService.refreshToken(refreshToken).pipe(
                switchMap((token: any) => {
                    this.isRefreshing = false;
                    this.storageService.set('token', token.body.accessToken);
                    this.refreshTokenSubject.next(token.body.accessToken);
                    return next.handle(this.addToken(request, token.body.accessToken));
                }));

        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(jwt => {
                    return next.handle(this.addToken(request, jwt));
                }));
        }
    }
}
